// i18next-extract-mark-ns-start cofidis-pay

import {Accordion as DefaultAccordion} from 'components/Accordion';
import {Content as DefaultContent} from 'components/Content';
import {AnchorLink} from 'components/AnchorLink';

import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';

import cofidis from 'images/cofidis_payline_6x12x24x.svg';
import step1 from 'images/cofidis_cart.svg';
import step2 from 'images/cofidis_request.svg';
import step3 from 'images/cofidis_calendar.svg';
import step4 from 'images/cofidis_card.svg';

import React from 'react';
import styled from 'styled-components';

const Content = styled(DefaultContent)`
  max-width: 54.1875rem;
`;

const Fold = styled.div`
  padding: 5rem 0 2rem;

  @media (max-width: 768px) {
    padding: 2.5rem 0;
  }
`;

const Logo = styled.div`
  text-align: center;
  margin-bottom: 0.625rem;
`;

const Header = styled.h1`
  color: #d61e38;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.375rem;
    line-height: 1.5rem;
  }
`;

const Subheader = styled.p`
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1rem;
  }
`;

const Steps = styled.ul`
  display: flex;
  justify-content: center;
  gap: 0.9375rem;
  list-style: none;
  margin: 0 0;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Step = styled.li`
  font-size: 0.9375rem;
  line-height: 1.0625rem;
  text-align: center;
  width: 12.5rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1rem;
    width: 47%;
  }
`;

const StepImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0.3125rem;
  height: 6.25rem;
  width: 6.25rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const StepTitle = styled.h3`
  font-size: 0.9375rem;
  line-height: 1.0625rem;
  font-weight: bold;
  margin-bottom: 0.3125rem;
`;

const StepCaption = styled.p``;

const SectionTitle = styled.h2`
  font-size: 0.9375rem;
  line-height: 1.0625rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.375rem;
`;

const FaqSection = styled.section`
  padding: 2.5rem 0 0.625rem;
`;

const Accordion = styled(DefaultAccordion)`
  margin-bottom: 0.625rem;
`;

const Help = styled.p`
  font-size: 0.9375rem;
  line-height: 1.4375rem;
  text-align: center;
  margin-top: 2.5rem;

  a {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: #848484;
  }
`;

const LegalSection = styled.section`
  font-size: 0.75rem;
  line-height: 0.9375rem;
  padding: 2.5rem 0;

  strong {
    font-weight: bold;
  }

  p {
    margin: 1.25rem 0;
  }
`;

const Page: React.FC = () => {
  return (
    <>
      <SEO
        path="cofidis-pay"
        title="Cofidis Pay"
        description="A quick and easy way to finance your payments with Cofidis in up to 50 monthly installments through direct debit."
      />
      <Content>
        <Fold>
          <Logo>
            <img src={cofidis} alt="6x 12x 24x Cofidis Pay" width={260} />
          </Logo>
          <Header>Financia tus compras en varias cuotas con cofidis</Header>
          <Subheader>
            La forma más sencilla de financiar tu compra en las cuotas que tú elijas con tu cuenta
            bancaria, y poder reutilizar Cofidis Pay 6x12x24x en tus futuras compras financiadas.
          </Subheader>
          <Steps>
            <Step>
              <StepImage>
                <img src={step1} alt="" />
              </StepImage>
              <StepTitle>De 75€ hasta 5.000€</StepTitle>
              <StepCaption>
                El importe mínimo a financiar es de 75€ hasta un máximo de 5.000€.
              </StepCaption>
            </Step>
            <Step>
              <StepImage>
                <img src={step2} alt="" />
              </StepImage>
              <StepTitle>Respuesta inmediata</StepTitle>
              <StepCaption>
                Obtendrás el resultado de tu solicitud de financiación de forma inmediata.*
              </StepCaption>
            </Step>
            <Step>
              <StepImage>
                <img src={step3} alt="" />
              </StepImage>
              <StepTitle>Flexibilidad de pago</StepTitle>
              <StepCaption>
                Las cuotas se cargarán en tu cuenta bancaria en los plazos que tú elijas, el día 2
                de cada mes.**
              </StepCaption>
            </Step>
            <Step>
              <StepImage>
                <img src={step4} alt="" />
              </StepImage>
              <StepTitle>Así de fácil</StepTitle>
              <StepCaption>
                Únicamente necesitas tu DNI y tu justificante de titularidad bancario.
              </StepCaption>
            </Step>
          </Steps>
        </Fold>
        <FaqSection>
          <SectionTitle>Preguntas frecuentes</SectionTitle>
          <Accordion header="1 - ¿Qué plazos puedo elegir?">
            <p>
              Principalmente puedes escoger 3, 6, 10, 12, 24 o 50 meses. La cuota mínima es 15€ y
              máxima 475,30€. Por ejemplo, para un pedido de 150€ dispondrás de 3, 6 y 10 cuotas a
              elegir. Para un pedido de 500€ podrás pedir también 12 o 24 y, para un pedido de
              1.000€, podrás solicitarlo a 50 meses. Por el contrario, no podrás realizar un pedido
              de 75€ a devolver en 50 meses porque la cuota resultante sería inferior a la mínima ni
              5.000€ a devolver en 3 meses porque la cuota resultante sería superior a la máxima.
            </p>
          </Accordion>
          <Accordion header="2 - ¿Cuánto tiempo necesitaré y qué documentos debo presentar?">
            <p>
              Normalmente de 5 a 7 minutos. Tan sólo completa tu solicitud online y adjunta las
              fotos de tu DNI/NIE y extracto bancario.
            </p>
          </Accordion>
          <Accordion header="3 - *¿Cuándo recibiré una respuesta?">
            <p>
              Para importes inferiores a 1000€ recibirás una respuesta inmediata. Para importes
              superiores a 1000€ recibirás una respuesta en menos de 48 horas. Si tu petición no es
              aprobada podrás finalizar tu compra a través de otro método de pago.
            </p>
          </Accordion>
          <Accordion header="4 - ¿Puedo utilizar Cofidis Pay 6x12x24x para todos los productos?">
            <p>
              Cofidis Pay 6x12x24x no está disponible para cestas que contengan cheques regalo de
              Partner, compras de productos digitales, productos en preventa, productos de
              alimentación o productos sin stock.
            </p>
          </Accordion>
          <Accordion header="5 - **¿Cuándo se realizarán los cargos en mi cuenta bancaria?">
            <p>
              Todas tus cuotas serán cargadas en tu cuenta bancaria el día 2 de cada mes, cargándose
              la primera cuota el mes posterior a la aceptación de la financiación.
            </p>
          </Accordion>
          <Accordion header="6 - ¿Cómo utilizar de nuevo mi Cofidis Pay 6x12x24x?">
            <p>
              Entre 4 y 6 días, después de la aceptación de tu Cofidis Pay 6x12x24x, verás
              disponible esta opción en tus métodos de pago. Para pedidos superiores a 75€ podrás
              utilizar tu Cofidis Pay 6x12x24x en tan sólo dos clics.
            </p>
          </Accordion>
          <Accordion header="7 - ¿Puedo devolver los productos si los compro con Cofidis Pay 6x12x24x?">
            <p>
              La política de devoluciones de Partner se aplica a todas las compras. Si recibes un
              reembolso completo, se te reembolsará la cantidad que ya has pagado, incluidos los
              intereses. En el caso de un reembolso parcial, el importe de las cuotas se modificará
              correspondientemente y el plazo se mantendrá.
            </p>
          </Accordion>
          <Accordion header="8 - ¿Cómo encontrar Cofidis Pay 6x12x24x durante el proceso de compra?">
            <p>Cambia el método de pago (ver arriba) y selecciona Cofidis Pay 6x12x24x.</p>
          </Accordion>
          <Help>
            ¿Tienes más preguntas?{' '}
            <AnchorLink href="https://www.cofidis.es/es/contactanos.html" target="_blank">
              Visita el apartado de Ayuda en Cofidis.es
            </AnchorLink>
          </Help>
        </FaqSection>
        <LegalSection>
          <SectionTitle>Términos y condiciones</SectionTitle>
          <p>
            La línea de crédito Cofidis Pay 6x12x24x lleva una comisión de gestión asociada a los
            gastos del servicio, por cada uso del producto. Esta comisión puede variar entre el 0 y
            el 5% del importe de la disposición, siendo su máximo 150€. En consecuencia, si haces 3
            disposiciones, se te aplicará una comisión de disposición determinada por cada una de
            las disposiciones, la cual podrá variar según el partner en el que realices la
            disposición (compra financiada) y las promociones que pueda tener en ese momento en
            favor de sus clientes, pero siempre dentro de los límites anteriormente identificados.
            La línea de crédito tiene una TAE desde 0 y hasta 33,88% y un interés (TIN) que puede
            variar entre el 0 y el 18% del importe de la disposición, el cual podrá variar según el
            partner en el que realices la disposición (compra financiada) y las promociones que
            pueda tener en ese momento en favor de sus clientes, pero siempre dentro de los límites
            anteriormente identificados.
          </p>
          <p>
            Financiación ofrecida y sujeta a la aprobación de Cofidis, S.A. Sucursal en España para
            importes entre 75 € y 5.000€ y plazos de 3 a 50 meses. Comisión de gestión por cada
            disposición variable entre el 0 y el 5% del importe de la disposición. TIN entre 0 y
            18%. La TAE desde 0 y hasta 33,88% varía en función del importe de la financiación, su
            plazo, el interés (TIN) y el importe de la comisión de gestión. Cuota mínima 15 € y
            máxima 475,30 €.
          </p>
          <p>
            En consecuencia, podrá variar según el comercio en el que realices la compra financiada
            y las promociones que pueda tener en este momento en favor de sus clientes.
          </p>
          <p>
            <strong>
              *Ejemplo de financiación para importe/PVP 75€. Plazo 3 meses. 3 cuotas de 25€. Importe
              total adeudado/precio total a plazos: 75€. Comisión de gestión en la primera
              disposición: 0€. Coste total del crédito: 0€. TIN 0%. TAE mínima 0%. Ejemplo de
              financiación para importe/PVP 3000€. Plazo 10 meses. 1 cuota inicial de 475,30€, 8
              cuotas de 325,30€ y 1 cuota residual de 323,81€. Importe total adeudado/precio total a
              plazos: 3.401,51€. Comisión de gestión en la primera disposición: 150€ (5%). Importe
              de los intereses: 251,51€. Coste total del crédito: 401,51. TIN 18%. TAE máxima
              33,88%.
            </strong>
          </p>
          <p>
            Sistema de amortización francés. Fecha de financiación del ejemplo representativo
            01/01/2022 y primera amortización 01/02/2022. Para otros importes y/o plazos, consulte
            condiciones de financiación.
          </p>
        </LegalSection>
      </Content>
    </>
  );
};

export default Page;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "cofidis-pay"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
